








































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import DaySettings from '@/components/DaySettings.vue';
import Selector from '@/components/Selector.vue';
import ChatInfoUser from '@/components/ChatInfoUser.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import DialogAddDefaultBackupContact from '@/components/DialogAddDefaultBackupContact.vue';
import {settingsStore} from '@/store/modules/settings';
import {WorkingDay} from '@/domain/model/types';

@Component({
  name: 'appointments',
  components: {DialogAddDefaultBackupContact, VcSwitch, ChatInfoUser, Selector, DaySettings, SettingsItem}
})
export default class Appointments extends Vue {

  showDialogAddDefaultAppointContact: boolean = false;

  times: any[] = [
    {name: '10 min', value: 10},
    {name: '15 min', value: 15},
    {name: '20 min', value: 20},
    {name: '30 min', value: 30},
    {name: '1 hr', value: 60}
  ]

  remindTimes: string[] = [
    '0 min',
    '15 min',
    '30 min',
    '1 hour'
  ];

  @Watch('appoints', {deep: true, immediate: false})
  onChanged(after, before) {
    if (!before) {
      return;
    }
    settingsStore.changeAppointments(after);
  }

  get appoints() {
    return settingsStore.businessSettings?.appoints;
  }

  get appointContact() {
    return this.appoints && this.appoints.contact;
  }

  get workingDays() {
    return settingsStore.businessSettings?.workingDays || []
  }

  workingDay(index: number): WorkingDay {
    return this.workingDays[index]
  }

  onRemoveAppointContact() {
    if (this.appoints) {
      this.appoints.contact = null;
    }
  }

  onAddAppointContact() {
    this.showDialogAddDefaultAppointContact = true;
  }

  onDefaultAppointmentContactSelected(contact: any) {
    settingsStore.setDefaultAppointmentsContact(contact)
  }
}
